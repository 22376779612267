body {
	background-color: white;
}

/*--- navigation bar ---*/

.navbar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: none;
	z-index: 1;
}

.logo {
	fill: #ffffff;
}

.nav-link,
.navbar-brand {
	color: #fff;
}

@media only screen and (max-width : 768px) {
  .navbar {
	  background: white;
	  }
  .logo {
	  fill: #0c2344;
	  }
  .nav-link {
	  color: #3a3a3a;
	  }
}

/*--- video header ---*/

header {
  position: relative;
  top: 0;
  background-color: black; 
  height: 50vh;
  min-height: 550px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

header .container {
  position: relative;
  margin: auto;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
}

header .video_bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0193da;
  z-index: -1;
}

@media only screen and (max-width : 768px) {
	.hero-title {
	font-size: 3.25em;
	margin-top: 2em;
	}
	.hero-subtitle {
		font-size: 1em;
	}
}

/*--- about us ---*/

.aboutus {
	position: relative;
	max-width: 1500px;
}

/*--- footer ---*/

footer {
	position: relative;
	margin-top: 35px;
}
